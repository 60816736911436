<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div>
          <p class="text-danger fw-bold text-capitalize" v-if="getErrors.payment">
            {{ getErrors.payment }}
          </p>

          <h1>{{ $t("Select bank account") }}</h1>
          <p class="fw-medium text-gray">
            {{
              $t(
                "Please select the bank account below on which you receive your monthly recurring income."
              )
            }}
          </p>
          <div class="d-flex">
            <b-spinner
              class="mx-auto"
              large
              label="Spinning"
              variant="primary"
              v-if="getLoadings.nordigen_accounts"
            >
            </b-spinner>
          </div>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio
              class="mb-2"
              v-model="form.account"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              :value="account.id"
              v-for="account in accounts"
              :key="account.id"
            >
              Account:
              {{ account.name }}
              <span v-if="account.iban"> : {{ account.iban }} </span>
            </b-form-radio>
          </b-form-group>
          <div
            class="text-muted text-center"
            v-if="accounts.length == 0 && !getLoadings.nordigen_accounts"
          >
            {{ $t("No Accounts found") }}
          </div>
          <div class="text-danger mt-5" v-if="getErrors.nordigen_accounts">
            {{ getErrors.nordigen_accounts }}
          </div>
          <div class="w-100 mt-4">
            <button
              class="btn btn-primary btn-block"
              :disabled="!form.account || getLoadings.nordigen_set_account"
              @click="onSubmit"
            >
              {{ $t("Next step") }}
              <b-spinner
                v-if="getLoadings.nordigen_set_account"
                small
                label="Spinning"
                variant="white"
              >
              </b-spinner>
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <!-- <img src="../../../assets/images/nordigen/nordigen_logo.png" alt=""> -->
          <!-- <img src="../../../assets/images/expat/step8.png" alt=""> -->
          <RightPoints active="5" />
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import RightPoints from "./RightPoints.vue";

export default {
  data() {
    return {
      error: "",
      form: {
        account: "",
      },
    };
  },
  components: {
    RightSection,
    AuthLayoutContent,
    RightPoints,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("nordigen", ["accounts"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tenant", ["tenant"]),
  },
  async created() {
    this.step = +this.$route.params.step;
    let tenant_id = this.$route.params.tenant_id;
    if (tenant_id) {
      this.getTenant(tenant_id);
    }
    this.getAccounts(tenant_id)
      .then(() => {})
      .catch((error) => {
        console.log("error", error);
      });
  },
  methods: {
    ...mapActions("nordigen", ["getAccounts", "setAccount"]),
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    async onSubmit() {
      let account_id = this.form.account;
      await this.setAccount({ account_id, tenant_id: this.tenant.id });

      this.$router.push({
        name: "ExpatStep9",
        params: { tenant_id: this.tenant.id },
      });
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}
</style>
